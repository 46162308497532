const intersectionObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // get nav item belonging to this section
                const el = document.getElementById(`${entry.target.id}-item`);

                el.classList.add("active");
            } else {
                const el = document.getElementById(`${entry.target.id}-item`);

                el.classList.remove("active");
            }
        });
    }, {
        threshold: .7,
    }
);

export default {
    created: (el) => {
        intersectionObserver.observe(el);
    },
};