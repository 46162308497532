<template>
  <Navigation />

  <Landing />

  <About />

  <Programs />

  <Donate />

  <Contacts />
</template>

<script>
import About from '../components/About.vue'
import Contacts from '../components/Contacts.vue'
import Donate from '../components/Donate.vue'
import Landing from '../components/Landing.vue'
import Navigation from '../components/Navigation.vue'
import Programs from '../components/Programs.vue'

export default {
  components: { Navigation, Landing, About, Programs, Donate, Contacts },
  name: 'Home',
}
</script>
