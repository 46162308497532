<template>
  <router-view/>
</template>

<style>
* {
  scroll-behavior: smooth;
}

#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

.input-field {
  padding: .5em .8em;
  border: 1px solid rgb(216, 216, 216);
  border-radius: .2em;
  transition: .3s border-color ease-in-out;
}

.input-field:focus {
  outline: none;
  border-color: rgb(160, 5, 5);
}

/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
