import { createApp } from 'vue'
import App from './App.vue'
import navItemTrigger from './directives/Navigation/navItemTrigger'
import navShadowTrigger from './directives/Navigation/navShadowTrigger'
import router from './router'
import './styles/tailwind.css'

createApp(App)
    .use(router)
    .directive('navItem', navItemTrigger)
    .directive('navShadow', navShadowTrigger)
    .mount('#app')