<template>
  <section
    v-navItem
    id="contacts"
    class="
      w-full
      bg-gray-700
      text-white text-center
      md:text-left
      px-10
      md:px-36
      lg:px-64
      py-10
    "
  >
    <div class="flex flex-col justify-center md:flex-row md:justify-between">
      <div class="md:w-1/3">
        <h4 class="text-white text-lg font-medium">COMMUNITY AIDS RESPONSES - CARE</h4>

        <br />
        <p class="text-gray-200 text-sm w-full">
          Providing care and ensuring human dignity for women and young people
          living with HIV and AIDS in rural Malawi.
        </p>
      </div>

      <div class="my-7 md:my-0 md:w-1/3">
        <h4 class="text-white text-lg font-medium">Quick Links</h4>

        <br />
        <ul>
          <li
            v-for="(navItem, i) in [
              { name: 'home', href: '#home' },
              { name: 'Programs', href: '#programs' },
              { name: 'donate', href: '#donate' },
            ]"
            :key="i"
          >
            <a
              :href="navItem.href"
              class="
                text-gray-200
                capitalize
                hover:text-white
                transition-colors
                text-sm
              "
            >
              {{ navItem.name }}
            </a>
          </li>
        </ul>
      </div>

      <div>
        <h4 class="text-white text-lg font-medium">Contacts</h4>

        <br />
        <div>
          <p class="text-gray-200 text-sm">
            &nbsp; CARE
          </p>
          <p class="text-gray-200 text-sm my-1">
            &nbsp;
            P.O. Box 3001, Lilongwe Malawi
          </p>
          <p class="text-gray-200 text-sm">
            &nbsp;
            info@caremalawi.org
          </p>
        </div>
      </div>
    </div>
    <hr class="my-5" />
    <div class="flex flex-col md:flex-row justify-between">
      <span class="text-gray-100 text-sm">&copy; CARE 2022 </span>

      <ul class="flex justify-between mt-7 md:mt-0">
        <li
          v-for="(social, i) in [
            { icon: 'facebook', href: '' },
            { icon: 'linkedin', href: '' },
            { icon: 'instagram', href: '' },
          ]"
          :key="i"
        >
          <a :href="social.href">
            <i
              :class="`fa-brands fa-${social.icon} text-gray-200 hover:text-white ml-3 transition-colors`"
            ></i>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>