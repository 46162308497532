<template>
  <section
    id="modal"
    class="
      fixed
      top-0
      left-0
      z-50
      w-full
      h-screen
      bg-black bg-opacity-50
      backdrop-filter backdrop-blur-sm
      flex
      justify-center
      items-center
    "
    v-show="show"
  >
    <div class="w-11/12 md:w-2/5 bg-white shadow-xl h-5/6 rounded-md relative overflow-auto">
      <div class="w-3/4 text-right py-2 px-20 fixed top-5 left-0">
        <span
          class="text-3xl text-white font-mono cursor-pointer"
          @click="onClose"
          >X</span
        >
      </div>

      <div class="w-full h-1/2">
        <img
          :src="`/images/${data.img}`"
          alt=""
          class="w-full h-full object-cover"
        />
      </div>

      <hr />
      <div class="p-4">
        <h4 class="font-medium text-xl">{{ data.title }}</h4>
      </div>
      <hr />

      <div class="p-4">
        <p class="text-justify mb-3" v-for="(p, i) in data.body" :key="i">
          {{ p }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: Object,
    show: Boolean,
    onClose: Function,
  },
};
</script>

<style>
</style>