<template>
  <section id="about" v-navItem class="w-full h-auto pt-10">
    <div
      class="
        w-full
        flex flex-col
        justify-center
        md:flex-row md:justify-between
        items-center
        px-10
        md:px-36
        lg:px-64
        py-10
      "
    >
      <icon-link
        v-for="(iconLinkItem, i) in iconLinkItems"
        :key="i"
        :href="iconLinkItem.href"
        :icon="iconLinkItem.icon"
        :text="iconLinkItem.text"
        class="w-full mb-7 md:mb-0 md:w-1/4"
      />
    </div>

    <div
      class="
        w-full
        flex flex-col
        justify-center
        md:flex-row md:justify-between
        items-center
        py-10
        px-10
        md:px-36
        lg:px-64
      "
    >
      <div class="md:w-1/2 md:mr-5">
        <div class="md:w-1/6">
          <heading text="About Us" />
        </div>

        <br />
        <p class="text-xl md:text-4xl capitalize">
          Providing care and ensuring human dignity for women and young people
          living with HIV and AIDS in rural Malawi.
        </p>

        <br />
        <p class="text-justify">
          Community AIDS Responses-CARE-Malawi is an NGO dedicated to providing
          care and supportive environments for people infected and affected by
          HIV and AIDS. CARE was established by people living with HIV/AIDS to
          provide direct and much needed timely support for women and children
          living with HIV and engage local communities in empowering them with
          skills and capacities to fight AIDS in their households and
          communities. CARE is a registered Charity Number: TR/INC: TR/INC:
          6540.
        </p>

        <br />
        <p class="text-justify">
          CARE- Malawi focus on ensuring that there is support provided for
          women and children living with HIV or orphaned by the decease. The
          Organization does this through Community engagement in HIV/AIDS
          through its innovative Care and Support Approaches to eradicate stigma
          and discrimination against people living with HIV/AIDS. CARE also
          engages young people in HIV prevention education, educational support
          for orphans and vulnerable children and creating safe and conducive
          environments where the people living with HIV/AIDS can live with human
          dignity.
        </p>
      </div>

      <div class="w-1/2 object-cover hidden md:flex md:justify-center md:items-center">
        <img src="/images/3081629.jpg" alt="" class="">
      </div>
    </div>

    <div
      class="
        w-full
        bg-gray-100
        py-10
        px-10
        md:px-36
        lg:px-64
        flex flex-col
        justify-center
        md:flex-row md:justify-between
        items-center
      "
    >
      <div class="md:w-1/2">
        <h4 class="font-bold text-xl mb-3">Our Mission</h4>
        <p>
          The Mission of CARE is to ensure provision of sustained care and
          support to women and children living with HIV as a way of mitigating
          the impact of HIV/AIDS.
        </p>
      </div>

      <div class="mt-7 md:mt-0 md:w-2/5">
        <h4 class="font-bold text-xl mb-3">Our Vision</h4>
        <p>
          Communities and individual able to effectively respond to HIV/AIDS and
          live in Human Dignity regardless of the HIV status.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Heading from "./heading.vue";
import IconLink from "./IconLink.vue";
export default {
  components: { IconLink, Heading },
  setup() {
    const iconLinkItems = [
      {
        icon: "handshake-angle",
        text: "volunteer",
        href: "#donate",
      },
      {
        icon: "donate",
        text: "Donate",
        href: "#donate",
      },
      {
        icon: "lightbulb",
        text: "Inspire",
        href: "#donate",
      },
    ];

    return { iconLinkItems };
  },
};
</script>

<style>
</style>