<template>
  <section id="home" v-navShadow v-navItem class="w-full h-screen">
    <div
      class="
        w-full
        h-full
        bg-black bg-opacity-30
        flex flex-col
        justify-center
        items-center
      "
    >
      <h4 class="text-white text-3xl md:text-6xl font-medium text-center uppercase">
        Be the change <br />
        you want to see in the world.
      </h4>
      <p class="mt-5 mx-3 md:mx-0 text-white text-center">
        Providing care and ensuring human dignity for women and young people
        <br/>living with HIV and AIDS in rural Malawi.
      </p>

      <a href="#about" class="mt-5 bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-sm shadow-xl px-3 py-2 font-medium text-gray-800">Learn About Us</a>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section {
  background-size: cover;
  animation: slider 30s ease-in-out infinite;
}

@keyframes slider {
  0% {
    background-image: url(/images/boy.jpg);
  }

  50% {
    background-image: url(/images/mother.jpg);
  }

  100% {
    background-image: url(/images/women.jpeg);
  }
}
</style>