<template>
  <div>
    <h4 class="text-lg font-bold text-red-800">{{text}}</h4>
    <div class="w-1/2 h-1 bg-red-800"></div>
  </div>
</template>

<script>
export default {
    props: {
        text: String
    }
};
</script>

<style>
</style>