<template>
  <section id="programs" v-navItem class="w-full px-10 md:px-36 lg:px-64 py-20">
    <div class="w-full flex justify-between items-center">
      <div class="md:w-1/2">
        <heading text="Our Programs" class="md:w-1/4" />
      </div>

      <div class="w-1/2 flex justify-end">
        <span
          class="
            bg-red-800
            hover:bg-red-700
            px-2
            shadow-md
            rounded-sm
            cursor-pointer
          "
          ><i class="fa-solid fa-angle-left text-white"></i
        ></span>
        <span
          class="
            bg-red-800
            hover:bg-red-700
            px-2
            shadow-md
            rounded-sm
            cursor-pointer
            ml-1
          "
          ><i class="fa-solid fa-angle-right text-white"></i
        ></span>
      </div>
    </div>

    <br /><br /><br />
    <div
      class="h-auto w-full flex flex-col justify-center md:flex-row md:justify-between"
    >
      <div
        class="
          w-80
          shadow-xl
          pb-3
          rounded-sm
          bg-gray-50
          flex flex-col
          justify-between
          mb-7
          md:mb-0
        "
        v-for="(program, i) in programs"
        :key="i"
      >
        <div class="h-1/2 w-full">
          <img
            :src="`/images/${program.img}`"
            alt=""
            class="h-full w-full object-cover"
          />
        </div>
        <div class="p-2">
          <h4 class="font-medium">{{ program.title }}</h4>
          <hr class="my-2" />
          <p class="text-justify text-sm">{{ trimBody(program.body[0]) }}...</p>
        </div>

        <div class="w-full text-left mt-2 px-2">
          <small
            class="
              font-medium
              cursor-pointer
              text-gray-500
              hover:text-gray-700
              transition-colors
            "
            @click="() => openModal(i)"
            >Read More...</small
          >
        </div>
      </div>
    </div>

    <br /><br />
    <div
      class="
        w-full
        flex flex-col
        justify-center
        md:flex-row md:justify-between
        items-center
        py-20
      "
    >
      <div
        class="w-full md:w-1/6 flex md:justify-between mb-7 md:mb-0"
        v-for="(indicator, i) in [
          { icon: 'spa', title: 'Beneficiaries', value: '3000' },
          { icon: 'hand-holding-heart', title: 'Volunteers', value: '100' },
          { icon: 'globe', title: 'Communities', value: '150' },
        ]"
        :key="i"
      >
        <div
          class="
            bg-red-800
            w-1/2
            flex
            justify-center
            items-center
            text-white
            rounded-sm
          "
        >
          <i :class="`fa-solid fa-${indicator.icon} text-2xl`"></i>
        </div>
        <div class="ml-5 md:ml-0">
          <p class="text-3xl font-medium">{{ indicator.value }}+</p>
          <small>{{ indicator.title }}</small>
        </div>
      </div>
    </div>
  </section>

  <modal
    :data="currentShowing"
    :onClose="() => onCloseHandler()"
    :show="modalState"
  />
</template>

<script>
import { ref } from "@vue/reactivity";
import heading from "./heading.vue";
import Modal from "./Modal.vue";
import { onBeforeMount } from "@vue/runtime-core";
import data from "../assets/data";

export default {
  components: { heading, Modal },
  setup() {
    let modalState = ref(false);
    const programs = data;
    let currentShowing = ref({});

    onBeforeMount(() => {
      currentShowing.value = programs[0];
    });

    const openModal = (program) => {
      currentShowing.value = programs[program];
      modalState.value = true;
    };

    const onCloseHandler = () => {
      modalState.value = !modalState.value;
    };

    const trimBody = (str) => {
      return str.slice(0, 160).trim();
    };

    return {
      modalState,
      programs,
      currentShowing,
      onCloseHandler,
      openModal,
      trimBody,
    };
  },
};
</script>

<style>
</style>