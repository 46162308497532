<template>
  <div
    class="
      w-full
      fixed
      z-40
      flex
      justify-between
      px-10
      md:px-36
      lg:px-64
      py-5
      bg-white
      transition-shadow
    "
    id="nav"
  >
    <h3 class="text-xl font-medium primary-text-color">CARE</h3>

    <ul class="md:flex justify-between hidden">
      <li v-for="(navItem, i) in navItems" :key="i" class="mx-2">
        <a
          :href="navItem.href"
          class="capitalize nav-item"
          :id="navItem.name + '-item'"
        >
          {{ navItem.name }}
        </a>
      </li>

      <li class="mx-3">
        <a href="#donate" class="bg-yellow-300 hover:bg-yellow-400 px-3 py-2 shadow-md transition-colors" id="donate-item">Donate</a>
      </li>
    </ul>

    <button class="block md:hidden" @click="trigger">
      <i class="fa-solid fa-bars"></i>
    </button>
  </div>

  <div :class="`fixed z-50 w-9/12 h-screen bg-white shadow-md px-10 py-10 transform ${state} transition-transform`">
    <p class="mb-5" @click="trigger">
      <span class="text-sm"><i class="fa-solid fa-angle-left mr-2"></i> Close Drawer</span>
    </p>
    <ul class="h-1/4 flex flex-col justify-between">
      <li v-for="(navItem, i) in navItems" :key="i" class="mx-2">
        <a
          :href="navItem.href"
          class="capitalize nav-item"
          :id="navItem.name + '-item'"
        >
          {{ navItem.name }}
        </a>
      </li>

      <li class="mx-2">
        <a href="#donate" class="capitalize nav-item" id="donate-item"></a>
      </li>
    </ul>
  </div>
</template>

<script>
import {computed, ref} from 'vue'

export default {
  setup() {
    const navItems = [
      { name: "home", href: "#home" },
      { name: "about", href: "#about" },
      { name: "programs", href: "#programs" },
      { name: "contacts", href: "#contacts" },
    ];

    let flag = ref(false)

    let state = computed(() => {
        return flag.value ? '-translate-x-0' : '-translate-x-full'
    })

    const trigger = () => {
        flag.value = !flag.value
    }

    return { navItems, state, trigger };
  },
};
</script>

<style scoped>
h3 {
  letter-spacing: 3px;
}

.nav-item {
  transition: font-weight 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.nav-item:hover {
  font-weight: bold;
}

.active {
  font-weight: bold;
}
</style>