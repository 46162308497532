const intersectionObserver = new IntersectionObserver((entries) => {
  const nav = document.getElementById('nav')

    entries.forEach(entry => {
        if (entry.isIntersecting) {
            nav.classList.remove('shadow-md')
        } else {
            nav.classList.add('shadow-md')
        }
    })
}, {
  threshold: 1,
});

export default {
  created: (el) => {
    intersectionObserver.observe(el)
  },
};
