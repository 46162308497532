export default [{
        img: "parallax1.jpg",
        title: "Care and Support for Women and Young people living with HIV and AIDS",
        body: ["At the core of the CARE programming is the provision of care and support services for women, children and young people living with HIV and AIDS. CARE has facilitated formation of Support Groups for women living with HIV and AIDS and those for young people. Through these support groups women and young people living with HIV have been linked with fellow positively living people to share experiences and encouragement and also they have been linked to service providers who provide counseling and other services including group ART education. CARE also provides nutritional support to women and lactating mothers living with HIV and AIDS and children."]
    },
    {
        img: 'adults.jpeg',
        title: 'HIV Prevention among women and young people.',
        body: ["In Malawi, 12% of adults (15-49 yrs) are HIV positive. Women represent 57% of HIV positive adults. And, 14.5% of girls ages 15-24 are HIV positive compared to 6.5% of young men. Girls living with HIV/AIDS drop out of school due to the stigma and overt hostility there and the unintended pregnancies due to the lack of S&RH. Few adolescents have access to primary prevention information. In this project CARE is networking with Community Based Organizations (CBOs) in Central Malawi. CARE engages young people with HIV prevention education and creating awareness using drama and theatre for development.",
            "Over the past 5 years, CARE-Malawi has created a network of community based groups working to educate young people on the prevention strategies of HIV using drama, live broadcasts and theatre for change. Young people ate being trained as peer educators leading the fight against HIV through empowering others in their villages. To ensure community ownership of the HIV interventions, CARE-Malawi has also created Women Mother Mentor Groups. These are groups of older women who are trained to provide counseling and peer- education to fellow women living with HIV/AIDS and also educate and mentor young girls on SRHR and Sexuality education issues and needs."
        ]
    },
    {
        img: 'mother.jpg',
        title: 'Prevention of further HIV transmission from mothers to their babies',
        body: [
            "Currently, Mother- to-Child Transmission constitutes roughly 30% of all HIV infections in Malawi which means 30,000 children are born infected with HIV every year. In the absence of integrated interventions, one half of these children will die before their first birthday. In the rural villages where CARE works, the situation is even dire as women and girls continue to be denied HIV/AIDS treatment and care services making them more vulnerable to HIV/AIDS. Women continue to suffer rights violations, social exclusion and stigma and discrimination which further prevent them from accessing PMTCT or excising their SRH rights. As a result, women and girls have no access to information on PMTCT, SRH and family planning that would teach them how to prevent transmission of HIV to their babies. ",
            "Evidence shows that, in rural Malawi, women living with HIV experience more stigma and rights violations in health care settings than HIV-negative women; preventing them to access ANC, PMTCT and other HIV/AIDS treatment, care and support services. Women experience most rights violations in ANC and SRH settings: Providers may not give comprehensive information on available contraceptives, or may automatically steer clients towards condoms or long acting contraception. Providers may condition access to antiretroviral treatment on contraceptive use. They may denigrate, and verbally or even physically abuse HIV-positive pregnant women- forcing them to terminate their pregnancies, or coercing them to be sterilized."
        ]
    },
    {
        img: 'boy.jpg',
        title: 'Malawi Education support interventions',
        body: [
            "The Community Child Care center approach. Under this approach CARE is working with the Malawi government to empower communities to provide community based care and support and early learning and stimulation to children below the age of 8. Communities have been trained to provide this support and they have mobilized resources to construct Community Based Child Care Centers.",
            "CARE- Malawi Secondary school education support approach. Under this arm, CARE provides direct support to girls and other disadvantaged children and young people at secondary school level. There are so many needs which these vulnerable children do lack and on top of school fees, CARE provides schools bags, pens, pencils and notebooks."
        ]
    }
];