<template>
  <section id="donate" v-navItem class="w-full py-20">
    <div class="w-full flex flex-col justify-center md:flex-row md:justify-between">
      <div class="md:w-1/2 h-96 object-cover">
        <img
          src="/images/mother.jpg"
          alt=""
          class="h-full w-full object-cover"
        />
      </div>

      <div class="md:w-1/2 bg-yellow-300 flex md:justify-start items-center">
        <div class="px-10 py-10 md:py-0 md:px-20">
          <h4 class="font-medium text-4xl text-gray-800 capitalize">
            The greatest art <br />
            is that of giving
          </h4>

          <br />
          <heading text="Become a Volunteer" class="md:w-1/4" />

          <br />
          <p class="md:w-1/2 text-gray-700">
            Your positive contribution no matter how small will make a big
            difference. Reach out to become a Volunteer.
          </p>

          <br />
          <div class="w-full">
            <form
              @submit.prevent=""
              class="
                flex flex-col
                justify-center
                md:flex-row md:justify-start
                items-center
              "
            >
              <input
                type="text"
                placeholder="Full Name"
                class="input-field w-full md:w-auto"
                required
              />
              <input
                type="text"
                placeholder="Email / Phone"
                class="input-field my-5 md:my-0 md:mx-4 w-full md:w-auto"
                required
              />
              <input
                type="submit"
                value="Send Request"
                class="
                  px-3
                  py-2
                  rounded-sm
                  shadow-md
                  bg-red-800
                  hover:bg-red-700
                  transititon-colors
                  text-white
                  cursor-pointer
                  w-full
                  md:w-auto
                "
              />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        w-full
        px-10
        md:px-36
        lg:px-64
        py-10
        flex flex-col
        justify-center
        md:flex-row md:justify-between md:items-start
      "
    >
      <div class="md:w-1/2 md:mr-3">
        <heading text="Insipre Someone" class="md:w-1/4" />

        <br />
        <p>
          Your story has the power to change, save and motivate someone's life.
          Let us share your experiences with people going through the same as you went
          through.
        </p>

        <br />
        <div class="w-full">
          <form
            @submit.prevent=""
            class="
              flex flex-col
              justify-center
              md:flex-row md:justify-start
              items-center
            "
          >
            <input
              type="text"
              placeholder="Full Name"
              class="input-field w-full md:w-auto"
              required
            />
            <input
              type="text"
              placeholder="Email / Phone"
              class="input-field my-5 md:my-0 md:mx-4 w-full md:w-auto"
              required
            />
            <input
              type="submit"
              value="Send Request"
              class="
                px-3
                py-2
                rounded-sm
                shadow-md
                bg-red-800
                hover:bg-red-700
                transititon-colors
                text-white
                cursor-pointer
                w-full
                md:w-auto
              "
            />
          </form>
        </div>
      </div>

      <div class="mt-12 md:mt-0 md:w-1/2 md:px-20">
        <heading text="Make a Donation" class="md:w-1/3" />

        <br />
        <p>
          As a nonprofit we rely on donations to continue and expand our work of
          providing care to people living with HIV and AIDs in
          rural Malawi.
        </p>

        <br />
          <div class="w-full">
            <form
              @submit.prevent=""
              class="
                flex flex-col
                justify-center
                md:flex-row md:justify-start
                items-center
              "
            >
              <input
                type="text"
                placeholder="Full Name"
                class="input-field w-full md:w-auto"
                required
              />
              <input
                type="text"
                placeholder="Email / Phone"
                class="input-field my-5 md:my-0 md:mx-4 w-full md:w-auto"
                required
              />
              <input
                type="submit"
                value="Send Request"
                class="
                  px-3
                  py-2
                  rounded-sm
                  shadow-md
                  bg-red-800
                  hover:bg-red-700
                  transititon-colors
                  text-white
                  cursor-pointer
                  w-full
                  md:w-auto
                "
              />
            </form>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import heading from "./heading.vue";
export default {
  components: { heading },
};
</script>

<style>
</style>