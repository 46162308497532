<template>
  <a :href="href">
    <div class="h-28 flex justify-between items-center">
      <div class="w-1/2 py-8 px-5 bg-yellow-300 rounded-sm text-center">
        <i :class="`fa-solid fa-${icon} text-6xl`"></i>
      </div>
      <div class="w-1/2 py-8 px-5 bg-yellow-400">
        <p class="text-gray-700 text-xl text-center font-bold">
            {{text}}
        </p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
    props: {
        href: String,
        icon: String,
        text: String,
    }
};
</script>

<style>
</style>